.zoom-in-top-enter-active,
.zoom-in-top-leave-active {
  opacity: 1;
  -webkit-transform: scaleY(1);
      -ms-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transform-origin: center top;
      -ms-transform-origin: center top;
          transform-origin: center top;
}
.zoom-in-top-enter,
.zoom-in-top-leave-active {
  opacity: 0;
  -webkit-transform: scaleY(0);
      -ms-transform: scaleY(0);
          transform: scaleY(0);
}
.v2-picker-panel__sidebar {
  position: absolute;
  background: #fff;
  width: 110px;
  height: 100%;
  border-right: 1px solid #edeef2;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 7px;
  cursor: default;
}
.v2-picker-panel__sidebar span {
  display: block;
  color: #333;
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  padding-left: 12px;
  cursor: pointer;
}
.v2-picker-panel__sidebar span:hover {
  color: #ff6200;
}
.v2-date-wrap {
  position: relative;
  display: inline-block;
  vertical-align: top;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  min-width: 150px;
  height: 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e1e2e6;
}
.v2-date-wrap:hover {
  border-color: #a1a4ad;
}
.v2-date-wrap.is-disabled {
  background: #eceff5;
  cursor: not-allowed;
}
.v2-date-wrap.is-disabled:hover {
  border-color: #e1e2e6;
}
.v2-date-wrap.is-disabled .v2-picker-trigger {
  cursor: not-allowed;
}
.v2-date-wrap .v2-picker-trigger {
  position: relative;
  display: block;
  height: 32px;
  line-height: 32px;
  padding-right: 20px;
  padding-left: 12px;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
}
.v2-date-wrap .v2-picker-trigger.empty-text {
  color: #c0c4cc;
}
.v2-date-wrap .v2-trigger-icon {
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 6px;
  content: '';
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: #ccc;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
}
.v2-date-wrap .v2-trigger-icon.active {
  margin-top: -8px;
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.v2-date-wrap .v2-date-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.v2-date-wrap .v2-date-clear {
  position: absolute;
  top: 50%;
  right: 21px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}
.v2-picker-panel-wrap {
  position: fixed;
  z-index: 1500;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 5px 0px;
  padding: 0;
  overflow: hidden;
}
.v2-picker-panel {
  position: relative;
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.v2-picker-panel__header {
  position: relative;
  margin-top: 5px;
  padding: 8px 8px 0 8px;
  text-align: center;
}
.v2-picker-panel__header .v2-picker-header__label {
  font-size: 14px;
  color: #333;
  cursor: default;
}
.v2-picker-panel__header .v2-picker-header__label span:last-child {
  padding-left: 3px;
  font-weight: bold;
}
.v2-picker-header__toggle {
  position: absolute;
  z-index: 2;
  top: 10px;
  width: 45px;
  height: 20px;
  line-height: 20px;
  cursor: pointer;
  font-size: 0;
}
.v2-picker-header__toggle.v2-picker-header__toggle-prev {
  left: 12px;
  text-align: left;
}
.v2-picker-header__toggle.v2-picker-header__toggle-next {
  right: 12px;
  text-align: right;
}
.v2-toggle-icon {
  display: inline-block;
  position: relative;
  height: 16px;
  width: 20px;
  cursor: pointer;
}
.v2-toggle-icon.v2-toggle-icon__prev-year::before,
.v2-toggle-icon.v2-toggle-icon__prev-year::after {
  position: absolute;
  top: 2px;
  content: '';
  width: 8px;
  height: 8px;
  border-top: 1px solid #8ea0b7;
  border-left: 1px solid #8ea0b7;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.v2-toggle-icon.v2-toggle-icon__prev-year::before {
  left: 4px;
}
.v2-toggle-icon.v2-toggle-icon__prev-year::after {
  left: 10px;
}
.v2-toggle-icon.v2-toggle-icon__prev-month::before {
  position: absolute;
  top: 2px;
  left: 6px;
  content: '';
  width: 8px;
  height: 8px;
  border-top: 1px solid #8ea0b7;
  border-left: 1px solid #8ea0b7;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.v2-toggle-icon.v2-toggle-icon__next-month::before {
  position: absolute;
  top: 2px;
  right: 6px;
  content: '';
  width: 8px;
  height: 8px;
  border-bottom: 1px solid #8ea0b7;
  border-right: 1px solid #8ea0b7;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.v2-toggle-icon.v2-toggle-icon__next-year::before,
.v2-toggle-icon.v2-toggle-icon__next-year::after {
  position: absolute;
  top: 2px;
  content: '';
  width: 8px;
  height: 8px;
  border-bottom: 1px solid #8ea0b7;
  border-right: 1px solid #8ea0b7;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.v2-toggle-icon.v2-toggle-icon__next-year::before {
  right: 4px;
}
.v2-toggle-icon.v2-toggle-icon__next-year::after {
  right: 10px;
}
.v2-toggle-icon.v2-toggle-icon-disabled {
  cursor: not-allowed;
}
.v2-toggle-icon.v2-toggle-icon-disabled.v2-toggle-icon__right-prev-year:hover::before,
.v2-toggle-icon.v2-toggle-icon-disabled.v2-toggle-icon__left-next-year:hover::before,
.v2-toggle-icon.v2-toggle-icon-disabled.v2-toggle-icon__right-prev-month:hover::before,
.v2-toggle-icon.v2-toggle-icon-disabled.v2-toggle-icon__left-next-month:hover::before,
.v2-toggle-icon.v2-toggle-icon-disabled.v2-toggle-icon__right-prev-year:hover::after,
.v2-toggle-icon.v2-toggle-icon-disabled.v2-toggle-icon__left-next-year:hover::after,
.v2-toggle-icon.v2-toggle-icon-disabled.v2-toggle-icon__right-prev-month:hover::after,
.v2-toggle-icon.v2-toggle-icon-disabled.v2-toggle-icon__left-next-month:hover::after {
  border-color: #8ea0b7;
}
.v2-toggle-icon__prev-year:hover::before,
.v2-toggle-icon__next-year:hover::before,
.v2-toggle-icon__prev-year:hover::after,
.v2-toggle-icon__next-year:hover::after {
  border-color: #ff6200;
}
.v2-toggle-icon__prev-month:hover::before,
.v2-toggle-icon__next-month:hover::before {
  border-color: #ff6200;
}
.v2-picker-panel__week-label {
  position: relative;
  cursor: default;
}
.v2-picker-panel__week-label span {
  display: table-cell;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 12px;
  text-align: center;
  color: #A1A4AD;
  font-weight: 400;
  border-bottom: 1px solid #edeef2;
}
.v2-picker-panel__content {
  position: relative;
  padding: 10px 8px;
}
.v2-picker-panel__week-label span,
.v2-picker-panel__day,
.v2-picker-panel__range-day {
  padding: 6px;
}
.v2-picker-panel__table {
  display: table;
  table-layout: fixed;
}
.v2-picker-panel__table .v2-picker-panel__table-row {
  display: table-row;
}
.v2-picker-panel__table .v2-picker-panel__table-cell {
  display: table-cell;
}
.v2-picker-panel__day {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}
.v2-picker-panel__day.next-month,
.v2-picker-panel__day.prev-month {
  color: #c0c4cc;
}
.v2-picker-panel__day.normal {
  color: #333;
}
.v2-picker-panel__day.normal:hover {
  color: #ff6200;
}
.v2-picker-panel__day.today {
  color: #ff6200;
}
.v2-picker-panel__day span {
  display: block;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.v2-picker-panel__day.selected span {
  color: #fff;
  background: #ff6200;
  border-radius: 50%;
}
.v2-picker-panel__day.disabled {
  background-color: #f4f4f4;
  opacity: 1;
  cursor: not-allowed;
  color: #ccc;
  border-radius: 0;
}
.v2-picker-panel__day.disabled:hover {
  color: #ccc;
  background-color: #f4f4f4;
}
.v2-picker-panel__day.disabled span {
  color: #ccc;
  background: #f4f4f4;
  border-radius: 0;
}
.v2-picker-panel__day.disabled.selected span {
  color: #fff;
  background: #ff6200;
  border-radius: 50%;
}
.v2-date-range-wrap {
  min-width: 215px;
}
.v2-picker-range-panel-wrap {
  font-size: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  white-space: nowrap;
}
.v2-picker-range-panel {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.v2-picker-range-panel.v2-picker-range__left-panel {
  border-right: 1px solid #edeef2;
}
.v2-picker-panel__range-day {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
}
.v2-picker-panel__range-day.next-month,
.v2-picker-panel__range-day.prev-month {
  color: #c0c4cc;
  cursor: default;
}
.v2-picker-panel__range-day.normal {
  color: #333;
  cursor: pointer;
}
.v2-picker-panel__range-day.normal:hover {
  color: #ff6200;
}
.v2-picker-panel__range-day.today {
  color: #ff6200;
}
.v2-picker-panel__range-day span {
  display: block;
  text-align: center;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.v2-picker-panel__range-day.in-range {
  background-color: #ffefe5;
}
.v2-picker-panel__range-day.in-range:hover {
  background-color: #ffd0b3;
}
.v2-picker-panel__range-day.start-date span,
.v2-picker-panel__range-day.end-date span {
  color: #fff;
  background: #ff6200;
  border-radius: 50%;
}
.v2-picker-panel__range-day.start-date {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}
.v2-picker-panel__range-day.end-date {
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
}
.v2-picker-panel__range-day.disabled {
  background-color: #f4f4f4;
  opacity: 1;
  cursor: not-allowed;
  color: #ccc;
  border-radius: 0;
}
.v2-picker-panel__range-day.disabled:hover {
  color: #ccc;
  background-color: #f4f4f4;
}
.v2-picker-panel__range-day.disabled span {
  color: #ccc;
  background: #f4f4f4;
  border-radius: 0;
}
.v2-picker-panel__range-day.disabled.start-date span,
.v2-picker-panel__range-day.disabled.end-date span {
  color: #fff;
  background: #ff6200;
  border-radius: 50%;
}
